import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { AssessmentCentralService } from "src/app/shared/services/assessment-central.service";
import { SurveyService } from "src/app/shared/services/survey.service";
import { map, startWith } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { CommonService } from "src/app/shared/services/common.service";
import { LoggingService } from "src/app/logging.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { AiroService } from "src/app/shared/services/airo.service";

@Component({
  selector: "app-create-pulse-dialog",
  templateUrl: "./create-pulse-dialog.component.html",
  styleUrls: ["./create-pulse-dialog.component.scss"],
  providers: [DatePipe],
})
export class CreatePulseDialogComponent implements OnInit {
  file: any;
  fileName: any = null;
  showFile = true;
  pulseInfo: any;
  isEditMode = false;
  filterOptionProgram: Observable<any[]>;
  filterOptionPortfolio: Observable<any[]>;
  filterOptionSubPortfolio: Observable<any[]>;
  filterOptionProject: Observable<any[]>;
  filterOptionVendor: Observable<any[]>;
  masterInformation: any;
  filteredOptions1: any;
  filteredOptions2: any;
  filteredOptions3: any;
  options1: any;
  options2: any;
  options3: any;
  subPortOptions: any;
  subPortfilteredOptions: any;
  programOption: any;
  projectOption: any;
  vendorOption: any;
  performanceImpactList: any = [];
  displayLabels: any;
  showDownload = false;
  showUpload = true;
  dataLoaded = true;
  pulseStatus: any = null;
  templateForm = this.fb.group({
    title: new FormControl("", [Validators.required]),
    description: new FormControl(""),
    portfolio_id: new FormControl(""),
    sub_portfolio_id: new FormControl(""),
    program_id: new FormControl(""),
    project_id: new FormControl(""),
    vendor_id: new FormControl(""),
    performance_impact_id: new FormControl("", [Validators.required]),
    start_date: new FormControl("", [Validators.required]),
    end_date: new FormControl("", [Validators.required]),
    is_positive: new FormControl(false),
  });

  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<CreatePulseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly toastrService: ToastrService,
    private readonly surveyService: SurveyService,
    private readonly spinner: NgxSpinnerService,
    private readonly assessmentCentralService: AssessmentCentralService,
    private readonly datePipe: DatePipe,
    private readonly commonService: CommonService,
    private readonly loggingService: LoggingService,
    private readonly filterService: FilterService,
    private readonly airoService: AiroService
  ) {}

  ngOnInit() {
    this.getDisplayLables();
    this.getMaster();
    this.getProgram();
    this.getProject();
    this.getVendor();
    this.getPerformanceImpactList();
    if (this.data.pulseId) {
      this.getPulseInfo(this.data.pulseId);
    } else if (this.data.projectPulseInfo) {
      this.pulseInfo = this.data.projectPulseInfo;
      this.initialiseProjectPulseData();
    } else {
      this.loggingService.warn("Unexpected value");
    }
  }

  getDisplayLables() {
    this.displayLabels = this.commonService.getLabels();
  }

  initialiseProjectPulseData() {
    this.templateForm.patchValue({ portfolio_id: this.pulseInfo.portfolio });
    this.templateForm.patchValue({
      sub_portfolio_id: this.pulseInfo.sub_portfolio,
    });
    this.templateForm.patchValue({ program_id: this.pulseInfo.program });
    this.templateForm.patchValue({ project_id: this.pulseInfo.project });
  }

  getPulseInfo(pulseId: any) {
    this.dataLoaded = false;
    this.surveyService.getPulseInfo(pulseId).subscribe((resp: any) => {
      this.pulseInfo = resp;
      this.dataLoaded = true;
      this.initialiseData();
    });
  }

  initialiseData() {
    this.templateForm.patchValue(this.pulseInfo);
    this.templateForm.patchValue({ portfolio_id: this.pulseInfo.portfolio });
    this.templateForm.patchValue({
      sub_portfolio_id: this.pulseInfo.sub_portfolio,
    });
    this.templateForm.patchValue({ program_id: this.pulseInfo.program });
    this.templateForm.patchValue({ project_id: this.pulseInfo.project });
    this.templateForm.patchValue({ vendor_id: this.pulseInfo.vendor });
    this.templateForm.patchValue({
      performance_impact_id: this.getPerformanceImpactValue(),
    });
    this.fileName = this.pulseInfo?.file_name;
    this.isEditMode = true;
    if (this.pulseInfo.file_name) {
      this.showDownload = true;
      this.showUpload = false;
    } else {
      this.showDownload = false;
      this.showUpload = true;
    }
    if (this.fileName === "null") {
      this.showFile = false;
    }
  }

  getPerformanceImpactValue() {
    const impactList: any = [];
    this.pulseInfo.performance_impact.forEach((impact: any) => {
      impactList.push(impact.id);
    });
    return impactList;
  }

  getPerformanceImpactList() {
    this.surveyService.getPerformanceImpactList().subscribe((resp: any) => {
      this.performanceImpactList = resp;
    });
  }

  getMaster() {
    this.assessmentCentralService.getMasterForm().subscribe((res: any) => {
      this.masterInformation = res?.records;

      this.masterInformation?.map((i: any) => {
        if (i.field_name === "opportunity_portfolio") {
          this.data.portfolio = i.field_status;
          this.options1 = i.field_values;
          this.filteredOptions1 = this.options1;
          this.data.disPortfolio = i.display_name;
          if (!i.field_status) {
            this.templateForm.removeControl("portfolio_id");
          }
        }
        if (i.field_name === "opportunity_sub_portfolio") {
          this.data.subPortfolio = i.field_status;
          this.subPortOptions = i.field_values;
          this.subPortfilteredOptions = this.subPortOptions;
          this.data.disSubPortfolio = i.display_name;
          if (!i.field_status) {
            this.templateForm.removeControl("sub_portfolio_id");
          }
        }
      });

      this.initAutoComplete();
    });
  }

  getProgram() {
    this.assessmentCentralService.getProgram().subscribe((res: any) => {
      this.programOption = res;
      const programControl = this.templateForm.get("program_id");
      if (programControl) {
        this.filterOptionProgram = programControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return this.programFilter(name);
            } else {
              return this.programOption.slice();
            }
          })
        );
      }
    });
  }

  getProject() {
    this.assessmentCentralService.getProject().subscribe((res: any) => {
      this.projectOption = res;
      const projectControl = this.templateForm.get("project_id");
      if (projectControl) {
        this.filterOptionProject = projectControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return this.projectFilter(name);
            } else {
              return this.projectOption.slice();
            }
          })
        );
      }
    });
  }

  getVendor() {
    this.assessmentCentralService.getVendor().subscribe((res: any) => {
      this.vendorOption = res.records[0].field_values;
      const vendorControl = this.templateForm.get("vendor_id");
      if (vendorControl) {
        this.filterOptionVendor = vendorControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            if (typeof value === "string") {
              return value;
            } else {
              return value?.name;
            }
          }),
          map((name: any) => {
            if (name) {
              return this.vendorFilter(name);
            } else {
              return this.vendorOption.slice();
            }
          })
        );
      }
    });
  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.templateForm.patchValue({
      sub_portfolio_id: "",
    });

    this.filterService
      .getSubportfolioList(portFolioId)
      .subscribe((resp: any) => {
        this.subPortOptions = resp.records;
        this.subPortfilteredOptions = this.subPortOptions;
        const subportfolioControl = this.templateForm.get("sub_portfolio_id");
        if (subportfolioControl) {
          this.filterOptionSubPortfolio = subportfolioControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => {
              if (typeof value === "string") {
                return value;
              } else {
                return value?.name;
              }
            }),
            map((name: any) => {
              if (name) {
                return this.subPortfolioFilter(name);
              } else {
                return this.subPortfilteredOptions.slice();
              }
            })
          );
        }
      });
  }

  onSelectionChangeSubportfolio(e: any) {
    const subportFolioId = e.source.value.id;
    this.programOption = [];
    if (!subportFolioId) {
      return;
    }
    this.templateForm.patchValue({
      program_id: "",
    });

    this.filterService
      .getProgram(e.source.value.id)
      .subscribe(async (resp: any) => {
        this.programOption = resp.records;
        const programControl = this.templateForm.get("program_id");
        if (programControl) {
          this.filterOptionProgram = programControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => {
              if (typeof value === "string") {
                return value;
              } else {
                return value?.name;
              }
            }),
            map((name: any) => {
              if (name) {
                return this.programFilter(name);
              } else {
                return this.programOption.slice();
              }
            })
          );
        }
      });
  }

  onSelectionChangeProgram(e: any) {
    const programId = e.source.value.id;
    const subportfolioId: any =
      this.templateForm.get("sub_portfolio_id")?.value?.id;
    this.projectOption = [];
    if (!programId || !subportfolioId) {
      return;
    }
    this.templateForm.patchValue({
      project_id: "",
    });
    this.filterService
      .getProject(subportfolioId, programId)
      .subscribe(async (resp: any) => {
        this.projectOption = resp.records;
        const projectControl = this.templateForm.get("project_id");
        if (projectControl) {
          this.filterOptionProject = projectControl.valueChanges.pipe(
            startWith(""),
            map((value: any) => {
              if (typeof value === "string") {
                return value;
              } else {
                return value?.name;
              }
            }),
            map((name: any) => {
              if (name) {
                return this.projectFilter(name);
              } else {
                return this.projectOption.slice();
              }
            })
          );
        }
      });
  }

  initAutoComplete(): any {
    const portfolioControl = this.templateForm.get("portfolio_id");
    if (portfolioControl) {
      this.filterOptionPortfolio = portfolioControl.valueChanges.pipe(
        startWith(""),
        map((value: any) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.name;
          }
        }),
        map((name: any) => {
          if (name) {
            return this.portfolioFilter(name);
          } else {
            return this.filteredOptions1.slice();
          }
        })
      );
    }
  }

  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.programOption.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }

  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }

  private vendorFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.vendorOption.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortOptions.filter((i: any) =>
      i.name.toLowerCase().includes(filterValue)
    );
  }

  private projectFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.projectOption.filter((i: any) =>
      i.project_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(data: any) {
    if (data?.name) {
      return data.name;
    } else {
      return "";
    }
  }

  displayFnProject(data: any) {
    if (data?.project_name) {
      return data.project_name;
    } else {
      return "";
    }
  }

  downloadFile() {
    this.spinner.show();
    this.surveyService.downloadPulseFile(this.pulseInfo.id).subscribe(
      (res: any) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(res);
        downloadLink.setAttribute("download", this.fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.toastrService.success("File downloaded successfully");
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        throw error;
      }
    );
  }

  createPulse() {
    const formData: any = new FormData();
    const postdata = this.templateForm.value;
    if (postdata.is_positive) {
      postdata.is_positive = "True";
    } else {
      postdata.is_positive = "False";
    }
    postdata.start_date = this.datePipe.transform(
      postdata.start_date,
      "YYYY-MM-dd"
    );
    postdata.end_date = this.datePipe.transform(
      postdata.end_date,
      "YYYY-MM-dd"
    );
    formData.append("file", this.file);
    formData.append("file_name", this.fileName);
    if (postdata.sub_portfolio_id?.id) {
      formData.append("portfolio_id", postdata.portfolio_id.id);
    }
    if (postdata.sub_portfolio_id?.id) {
      formData.append("sub_portfolio_id", postdata.sub_portfolio_id.id);
    }
    if (postdata.program_id?.id) {
      formData.append("program_id", postdata.program_id.id);
    }
    if (postdata.project_id?.id) {
      formData.append("project_id", postdata.project_id.id);
    }
    if (postdata.vendor_id?.id) {
      formData.append("vendor_id", postdata.vendor_id.id);
    }
    formData.append("start_date", postdata.start_date);
    formData.append("end_date", postdata.end_date);
    formData.append("performance_impact_id", postdata.performance_impact_id);
    formData.append("is_positive", postdata.is_positive);
    formData.append("title", postdata.title);
    formData.append("description", postdata.description);
    this.spinner.show();
    if (!this.isEditMode) {
      this.surveyService.createPulse(formData).subscribe(() => {
        this.spinner.hide();
        this.toastrService.success("Pulse created successfully");
        this.dialogRef.close(formData);
      });
    } else {
      this.surveyService
        .updatePulse(formData, this.pulseInfo.id)
        .subscribe(() => {
          this.spinner.hide();
          this.toastrService.success("Pulse updated successfully");
          this.dialogRef.close(formData);
        });
    }
  }

  onFileChange(event: any) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    event.target.value = "";
  }

  closeDialog() {
    this.dialogRef.close();
  }

  removeFile() {
    this.file = null;
    this.fileName = null;
    this.showDownload = false;
    this.showUpload = true;
  }

  isAskAiroDisabled() {
    if (this.file && this.templateForm.value["description"]) {
      return false;
    } else {
      return true;
    }
  }

  askAiro() {
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("description", this.templateForm.value["description"]);
    this.dataLoaded = false;
    this.pulseStatus = null;
    this.airoService
      .getToken()
      .then(() => {
        this.airoService.detectPulse(formData).subscribe(
          (res: any) => {
            this.dataLoaded = true;
            if (res.success) {
              if (res.data.positive) {
                this.templateForm.patchValue({ is_positive: true });
                this.pulseStatus = "positive";
              } else {
                this.templateForm.patchValue({ is_positive: false });
                this.pulseStatus = "negative";
              }
            }
          },
          (error: any) => {
            this.dataLoaded = true;
            this.toastrService.error(error?.error?.message);
          }
        );
      })
      .catch((error: any) => {
        this.toastrService.error(error.message);
        this.dataLoaded = false;
      });
  }
}
