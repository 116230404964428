import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { from, Observable, throwError, of } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { LoggingService } from "src/app/logging.service";
import { AuthService } from "../services/auth.service";

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  passToken: any;
  errorText = "Oops, something went wrong. Our team is working on it!";
  unexpectedErrorText = "Something went wrong. Please try again later.";

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private readonly loggingService: LoggingService,
    private readonly authService: AuthService
  ) {}

  raiseErrorToast(err: any) {
    this.toastr.error(
      err.error?.detail ||
        err.error?.message ||
        err.error?.error ||
        err.error ||
        this.errorText
    );
  }
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    this.spinner.hide();

    if (this.isUnexpectedError(err)) {
      // Handle unexpected errors like network disconnect or timeout
      this.toastr.error(this.unexpectedErrorText);
      return throwError(this.unexpectedErrorText);
    }

    const forbiddenStatus = 403;
    const badStatus = 400;
    const notFoundStatus = 404;

    if (this.isTokenExpired(err)) {
      return this.handleTokenExpiration(err);
    }

    if (err.status === forbiddenStatus) {
      this.raiseErrorToast(err);
    } else if (err.status === badStatus) {
      this.handleBadRequest(err);
    } else if (err.status !== notFoundStatus) {
      this.raiseErrorToast(err);
    } else {
      this.loggingService.log("Unexpected error", err);
    }

    return throwError(this.errorText);
  }

  private isUnexpectedError(err: HttpErrorResponse): boolean {
    return (
      err.status === 0 || // No internet connection or CORS error
      err.message?.includes("timeout") // Timeout error
    );
  }

  private isTokenExpired(err: HttpErrorResponse): boolean {
    const expireStatus = 503;
    const unauthorizedStatus = 401;
    return (
      err.status === expireStatus ||
      (err.status === unauthorizedStatus &&
        err.error?.detail?.includes("Signature has expired")) ||
      err.error?.detail?.includes("Error decoding signature.")
    );
  }

  private handleTokenExpiration(err: HttpErrorResponse): Observable<any> {
    const unauthorizedStatus = 401;

    if (err.status === unauthorizedStatus) {
      if (
        err.error?.detail?.includes("Signature has expired") ||
        err.error?.detail?.includes("Invalid signature.") ||
        err.error?.detail?.includes("Error decoding signature.")
      ) {
        this.toastr.error(err.error?.detail);
      }
      localStorage.clear();
      this.authService.setLoginStatus(false);
      this.router.navigate(["auth/login"]);
    }

    return of(err.message);
  }

  private handleBadRequest(err: HttpErrorResponse): void {
    this.toastr.error(
      err.error?.detail ||
        err.error?.message ||
        err.error?.error ||
        this.errorText
    );
  }

  handleAuthInterception(req: any, next: any, url: any) {
    let path = "";
    path += `${url}/v1/${req.url}`;
    req = req.clone({
      setHeaders: {
        "Content-Type": "application/json",
      },
      url: path,
    });
    return next.handle(req);
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const tempHost = window.location.host;
    const url = environment.keplerServerURL;

    if (this.isVendorList(req.url)) {
      req = this.addVendorSubdomain(tempHost, req);
    }

    if (this.isDevEnvironment(req.url)) {
      return this.handleDevEnvironment(req, next);
    }

    if (this.isTagRequest(req.url)) {
      return this.handleTagRequest(req, next, url);
    }

    if (this.isAuthRequest(req.url)) {
      return this.handleAuthRequest(req, next, url);
    }

    if (this.isFormsRequest(req.url)) {
      return this.handleFormsRequest(req, next, url);
    }

    if (this.isUserExistsRequest(req.url)) {
      return this.handleAuthInterception(req, next, url);
    }

    if (this.isSignupOrVerifyRequest()) {
      return this.handleAuthInterception(req, next, url);
    }

    if (
      this.isPowerBiRequest(req.url) ||
      this.isDevEnvironment(req.url) ||
      this.isAiroRequest(req.url)
    ) {
      return this.handlePowerBiOrDevRequest(req, next);
    }

    return this.handleDefaultRequest(req, next, url);
  }

  private isVendorList(url: string): boolean {
    return window.location.href.includes("vendor/list");
  }

  private addVendorSubdomain(
    tempHost: string,
    req: HttpRequest<any>
  ): HttpRequest<any> {
    const subdomain = tempHost.split(".");
    subdomain.splice(1, 0, "vendor");
    const vendorUrl = `${window.location.protocol}//${subdomain.join(".")}`;
    return req.clone({ url: vendorUrl });
  }

  private isDevEnvironment(url: string): boolean {
    return url.includes("dev.airo.kairhos.com");
  }

  private handleDevEnvironment(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(req.clone({ url: req.url }));
  }

  private isTagRequest(url: string): boolean {
    return url.includes("tag_ids");
  }

  private handleTagRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    url: string
  ): Observable<any> {
    const path = `${url}/v1/${req.url}`;
    if (!req.url.includes("graph.microsoft.com")) {
      return this.addAuthHeader(req, next, path);
    } else {
      return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
    }
  }

  private isAuthRequest(url: string): boolean {
    const authRequests = [
      "auth",
      "reset",
      "get-user-profile",
      "password-reset-confirm",
      "api-password-reset-verify",
      "api-token-verify",
    ];
    return authRequests.some((request) => url.includes(request));
  }

  private handleAuthRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    url: string
  ): Observable<any> {
    const path = `${url}/${req.url}`;
    req = req.clone({ url: path });

    if (req.url.includes("password-reset-confirm")) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.passToken = params["tk"];
      });
      req = req.clone({
        setHeaders: {
          "Content-Type": "application/json",
        },
      });
    }

    return next.handle(req) || of("error");
  }

  private isFormsRequest(url: string): boolean {
    return url.includes("forms");
  }

  private handleFormsRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    url: string
  ): Observable<any> {
    const path = `${url}/api/v1/${req.url}`;
    return this.addAuthHeader(req, next, path);
  }

  private isUserExistsRequest(url: string): boolean {
    const userRequests = [
      "check-user-exists",
      "get-token-by-sso",
      "vendors/otp",
    ];
    return userRequests.some((request) => url.includes(request));
  }

  private isSignupOrVerifyRequest(): boolean {
    return (
      window.location.href.includes("signup") ||
      window.location.href.includes("verify")
    );
  }

  private isPowerBiRequest(url: string): boolean {
    return url.includes("api.powerbi.com");
  }

  private isAiroRequest(url: string): boolean {
    return url.includes("keplerai.kairhos.com");
  }

  private handlePowerBiOrDevRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(req.clone({ url: req.url }));
  }

  private handleDefaultRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    url: string
  ): Observable<any> {
    let path = `${url}/v1/${req.url}`;
    if (this.isAiroRequest(url)) {
      path = `${url}/${req.url}`;
    }
    if (!req.url.includes("graph.microsoft.com")) {
      return this.addAuthHeader(req, next, path);
    }
    return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
  }

  private addAuthHeader(
    req: HttpRequest<any>,
    next: HttpHandler,
    path: string
  ): Observable<any> {
    return from(this.getToken()).pipe(
      mergeMap((token: any) => {
        req = req.clone({
          setHeaders: {
            authorization: `JWT ${token}`,
          },
          url: path,
        });
        return next
          .handle(req)
          .pipe(catchError((x) => this.handleAuthError(x)));
      })
    );
  }

  getToken() {
    return new Promise<any>((resolve, reject) => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          resolve(token);
        } else {
          reject(new Error("token not found"));
        }
      } catch (err) {
        reject(new Error("token not found"));
      }
    });
  }
}
