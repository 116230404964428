import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AiroService {
  constructor(private readonly http: HttpClient) {}
  private token: string | null = "";
  private readonly AIRO_URL = "https://keplerai.kairhos.com/api";
  getAssessmentAiSummary(data: any) {
    return this.http.post(
      `${this.AIRO_URL}/assessment/generate-summary`,
      data,
      { headers: { Authorization: "Bearer " + this.token } }
    );
  }

  getSurveyAiSummary(data: any) {
    return this.http.post(`${this.AIRO_URL}/survey/generate-summary`, data, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  login() {
    return this.http.post(`${this.AIRO_URL}/login`, {
      email: environment.airoUser.email,
      password: environment.airoUser.password,
    });
  }

  verifyToken() {
    return this.http.get(`${this.AIRO_URL}/health-check`, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  refreshToken() {
    return this.http.post(
      `${this.AIRO_URL}/refresh-token`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("airo_refresh_token"),
        },
      }
    );
  }

  clearToken() {
    this.token = "";
    localStorage.removeItem("airo_access_token");
    localStorage.removeItem("airo_refresh_token");
  }

  async getToken() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("airo_access_token")) {
        this.token = localStorage.getItem("airo_access_token");
        this.verifyToken().subscribe(
          (res: any) => {
            resolve(this.token);
          },
          (error: any) => {
            this.refreshToken().subscribe(
              (res: any) => {
                localStorage.setItem(
                  "airo_access_token",
                  res?.data?.access_token
                );
                this.token = res?.data?.access_token;
                resolve(this.token);
              },
              (err: any) => {
                reject(new Error("Unable to refesh access token"));
              }
            );
          }
        );
      } else {
        this.login().subscribe(
          (res: any) => {
            localStorage.setItem("airo_access_token", res?.data?.access_token);
            localStorage.setItem(
              "airo_refresh_token",
              res?.data?.refresh_token
            );
            this.token = localStorage.getItem("airo_access_token");
            resolve(this.token);
          },
          (error) => {
            reject(new Error("Wrong Credentials"));
          }
        );
      }
    });
  }

  uploadFile(data: any) {
    return this.http.post(`${this.AIRO_URL}/s3-file-upload`, data, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  fetchFileData(fileIds: any) {
    return this.http.get(
      `${this.AIRO_URL}/files?file_ids=` + fileIds.join(","),
      { headers: { Authorization: "Bearer " + this.token } }
    );
  }

  createConversation(payload: any) {
    return this.http.post(`${this.AIRO_URL}/conversation`, payload, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  getSingleConversation(conversationId: any) {
    return this.http.get(`${this.AIRO_URL}/conversation/${conversationId}`, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  intiateConversation(payload: any, id: any) {
    return this.http.post(`${this.AIRO_URL}/chat/${id}`, payload, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  chatStreamObservable(url: string, observer: any) {
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    })
      .then((response) => {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        if (!response.ok) {
          // handle response error
          observer.error();
        }

        function push() {
          return reader?.read().then(({ done, value }) => {
            if (done) {
              observer.complete();
              return;
            }
            //parse text content from response
            const events = decoder.decode(value);
            let content = "";
            for (const element of events) {
              content += element;
            }
            observer.next(content);
            push();
          });
        }

        push();
      })
      .catch((err: Error) => {
        // handle fetch error
        observer.error();
      });
  }

  chatStream(id: string, regenerate = false) {
    let url = this.AIRO_URL;
    if (regenerate) {
      url += `/re-generate/message/${id}`;
    } else {
      url += `/generate-answer/${id}`;
    }
    return new Observable<string>((observer) => {
      this.chatStreamObservable(url, observer);
    });
  }

  detectTopic(payload: any) {
    return this.http.post(`${this.AIRO_URL}/document-topic-detection`, payload, {
      headers: { Authorization: "Bearer " + this.token },
    });
  }

  detectPulse(payload: any) {
    return this.http.post(`${this.AIRO_URL}/detect-pulse`, payload,{
      headers: { Authorization: "Bearer " + this.token },
    });
  }

}
