import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AvatarModule } from "ngx-avatar";
import { NgxPermissionsModule } from "ngx-permissions";
import { SwiperModule } from "swiper/angular";
import { HeaderComponent } from "./components/header/header.component";
import { LeftSideMenuComponent } from "./components/left-side-menu/left-side-menu.component";
import { ModalComponent } from "./components/modal/modal.component";
import { SelectComponent } from "./components/select/select.component";
import { TableCardComponent } from "./components/table-card/table-card.component";
import { MaterialmodulesModule } from "./materialmodules.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatSliderModule } from "@angular/material/slider";
import { TransferState } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartModule } from "angular-highcharts";
import { HighchartsChartModule } from "highcharts-angular";
import { NgCircleProgressModule } from "ng-circle-progress";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { QuillModule } from "ngx-quill";
import { TimeagoModule } from "ngx-timeago";
import { PowerBIEmbedModule } from "powerbi-client-angular";
import { BreadCrumbComponent } from "./components/bread-crumb/bread-crumb.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { DeletePopupComponent } from "./components/delete-popup/delete-popup.component";
import { FooterComponent } from "./components/footer/footer.component";
import { GnewsComponent } from "./components/gnews/gnews.component";
import { LabelInputComponent } from "./components/label-input/label-input.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { ModalHeaderCommonComponent } from "./components/modal-header-common/modal-header-common.component";
import { OkrListingComponent } from "./components/okr-insight/okr-listing/okr-listing.component";
import { OwnerComponent } from "./components/owner/owner.component";
import { RightPanelStatementDetailsComponent } from "./components/right-panel-statement-details/right-panel-statement-details.component";
import { RisklogHeatmapComponent } from "./components/risklog-heatmap/risklog-heatmap.component";
import { SharedScopeComponent } from "./components/scope/scope.component";
import { IndividualScorecardComponent } from "./components/scorecard-matrix/individual-scorecard/individual-scorecard.component";
import { ScorecardComponent } from "./components/scorecard-matrix/scorecard/scorecard.component";
import { SelectionTreeListingComponent } from "./components/selection-tree-listing/selection-tree-listing.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { StatementResponseDetailsComponent } from "./components/statement-response-details/statement-response-details.component";
import { ConfirmationDialogComponent } from "./components/tag/confirmation-dialog/confirmation-dialog.component";
import { TagComponent } from "./components/tag/tag.component";
import { TagMappedProjectsComponent } from "./components/tags-mapped-projects/tags-mapped-projects.component";
import { TreeListingComponent } from "./components/tree-listing/tree-listing.component";
import { WorkgroupComponent } from "./components/workgroup/workgroup.component";
import { PipeModule } from "./pipes/pipe.module";
import { TreefilterPipe } from "./pipes/treefilter.pipe";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PowerBiModuleComponent } from "./power-bi-module/power-bi-module.component";
import { PulseComponent } from "./components/pulse/pulse.component";
import { OkrDialogComponent } from "./components/okr-dialog/okr-dialog.component";
import { FilterComponent } from "./filters/filter/filter.component";
import { SearchableMultiselectComponent } from "./filters/searchable-select/searchable-multiselect/searchable-multiselect.component";
import { SearchableSelectComponent } from "./filters/searchable-select/searchable-select/searchable-select.component";
import { DatepickerComponent } from "./filters/datepicker/datepicker/datepicker.component";
import { InputComponent } from "./filters/input/input/input.component";
import { MarkdownModule } from "ngx-markdown";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    SelectComponent,
    LeftSideMenuComponent,
    SideMenuComponent,
    TableCardComponent,
    TreeListingComponent,
    SelectionTreeListingComponent,
    GnewsComponent,
    TreefilterPipe,
    DeletePopupComponent,
    BreadCrumbComponent,
    TagComponent,
    ConfirmationDialogComponent,
    LoadingComponent,
    ConfirmModalComponent,
    LabelInputComponent,
    OwnerComponent,
    WorkgroupComponent,
    CommentsComponent,
    ScorecardComponent,
    IndividualScorecardComponent,
    RightPanelStatementDetailsComponent,
    StatementResponseDetailsComponent,
    RisklogHeatmapComponent,
    ModalHeaderCommonComponent,
    TagMappedProjectsComponent,
    SharedScopeComponent,
    OkrListingComponent,
    PowerBiModuleComponent,
    PulseComponent,
    OkrDialogComponent,
    FilterComponent,
    SearchableMultiselectComponent,
    SearchableSelectComponent,
    DatepickerComponent,
    InputComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxDatatableModule,
    FormsModule,
    NgxPermissionsModule,
    AvatarModule,
    SwiperModule,
    MaterialmodulesModule,
    NgbModule,
    NgCircleProgressModule.forRoot({}),
    Ng2SearchPipeModule,
    PipeModule,
    NgbPaginationModule,
    QuillModule,
    ChartModule,
    HighchartsChartModule,
    DragDropModule,
    PowerBIEmbedModule,
    MatSliderModule,
    MarkdownModule.forRoot(),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SelectComponent,
    TableCardComponent,
    LeftSideMenuComponent,
    SideMenuComponent,
    TreeListingComponent,
    SelectionTreeListingComponent,
    GnewsComponent,
    Ng2SearchPipeModule,
    AvatarModule,
    DeletePopupComponent,
    BreadCrumbComponent,
    PipeModule,
    NgbPaginationModule,
    TagComponent,
    LoadingComponent,
    LabelInputComponent,
    OwnerComponent,
    WorkgroupComponent,
    TimeagoModule,
    CommentsComponent,
    ScorecardComponent,
    IndividualScorecardComponent,
    RightPanelStatementDetailsComponent,
    StatementResponseDetailsComponent,
    RisklogHeatmapComponent,
    ModalHeaderCommonComponent,
    TagMappedProjectsComponent,
    OkrListingComponent,
    SharedScopeComponent,
    PdfViewerModule,
    MatCheckboxModule,
    PowerBiModuleComponent,
    PulseComponent,
    FilterComponent,
    SearchableMultiselectComponent,
    SearchableSelectComponent,
    DatepickerComponent,
    InputComponent,
  ],
  providers: [TransferState],
  entryComponents: [ConfirmationDialogComponent],
})
export class SharedModule {}
