import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { WorkForceService } from "src/app/shared/services/work-force.service";
import { MessageService } from "../../services/message.service";

@Component({
  selector: "app-gnews",
  templateUrl: "./gnews.component.html",
  styleUrls: ["./gnews.component.scss"],
})
export class GnewsComponent implements OnInit {
  browseAIData: any = [];
  @Input() pagename = "";
  @Input() displayHeader = true;
  llmformattedresponse: any;
  isExpanded: any;
  queryString: any;
  llmresponse: string;
  llmlinks: any;
  loading = false;
  calledOnce = 0;
  queryParams = {};
  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly workForceService: WorkForceService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.messageService.getGnews().subscribe((res: any) => {
      this.pagename = res.pagename;
      if(res?.queryParam){
        this.queryParams = res?.queryParam;
      }else{
        this.queryParams = {};
      }
      if (this.calledOnce<=1) {
        this.getBrowseAIData();
      }
      this.calledOnce++;
    });
  }

  getGMapRandomColor() {
    const colorNum = 360;
    const color = Math.floor(Math.random() * colorNum);
    return `hsla(${color}, 50%, 25%, 90%)`;
  }

  openNewWindow(url: any) {
    window.open(url, "_blank", "width=600,height=400");
  }

  getBrowseAIData() {
    this.spinner.show();
    this.loading = true;
    this.workForceService
      .getBrowseAIData(this.pagename, this.queryParams)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.queryString = res.query_string;
        this.llmresponse = res.summary;
        this.llmlinks = res.data;
        this.formatCurated();
        this.loading = false;
      });
  }

  formatCurated() {
    this.llmformattedresponse = this.replaceCitations(this.llmresponse.trim(), this.llmlinks);
  }

  replaceCitations(text: any, citationsArray: any) {
    return text.replace(/\[citation:(\d+)\]/g, (_: any, index: any) => {
      const citationIndex = parseInt(index, 10);
      if (citationsArray[citationIndex-1]?.url) {
        return `<a href="${citationsArray[citationIndex-1]?.url}" target="_blank"><span class="citation-links">${citationIndex}</span></a>`;
      } else {
        return `[citation:${index}]`;
      }
    });
  }
}
